import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueries, useQuery, useQueryClient } from 'react-query';
import Modal from '../../../SharedComponents/Modal';
import PecnilAlt from '../../../SharedComponents/Svg/PencilAlt';
import PlusCircle from '../../../SharedComponents/Svg/PlusCircle';
import { addUser, assignRole, getRoles } from './ApiUser';

export default function AddUser(props) {
  const queryClient = useQueryClient();
  const { t, i18n } = useTranslation();
  const roles = useQuery(['roles'], getRoles);
  const [isOpen, setIsOpen] = useState(false);
  const [newUserRole, setNewUserRole] = useState(1);
  const assignNewRole = useMutation(assignRole, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['users']);
      if (data.isDone) {
        setIsOpen(false);
      }
    },
  });
  const { mutate, data } = useMutation(addUser, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['users']);
      if (data.isDone) {
        try {
          const roleData = {
            userId: data.outId,
            rolesIds: [newUserRole],
            deleteOldRoles: true,
          };
          assignNewRole.mutate(roleData);
        } catch (error) {
        }
        // setIsOpen(false);
      }
    },
  });
  const onSubmit = async (e) => {
    const newData = { ...e, role: parseInt(e.role) };
    setNewUserRole(parseInt(e.role));

    try {
      await mutate(newData);
    } catch (error) {
      console.log('error', error);
    }
  };
  return (
    <>
      <button
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        className={`bg-lightgreen ${
          i18n.language === 'en' ? '' : 'space-x-reverse'
        } space-x-1 text-sm font-bold text-white flex items-center hover:bg-lightgreenhover shadow  px-4 py-2 rounded transition duration-300 ease-in-out`}
      >
        <PlusCircle className="w-5 h-5"/> <span>{t('Add User')}</span>
      </button>
      <Formik
        initialValues={{
          userName: '',
          phone: '',
          password: '',
          email: '',
          role: 1,
          fullName: '',
          isActive: true,
        }}
        onSubmit={(values, { resetForm }) => {
          onSubmit(values);
          // resetForm();
        }}
      >
        <Form>
          <Modal isOpen={isOpen} setIsOpen={setIsOpen} title={t('Add User')} add={true}>
            {data?.isDone === false && (
              <span className="border-red-400 mb-4 border text-center block rounded mt-4 text-red-400 p-2">
                {data?.isMessage}
              </span>
            )}
            <Field
              style={{ textIndent: '10px' }}
              placeholder={t('Enter Name')}
              className="border rounded  w-full mb-4 p-1"
              name="userName"
              type="text"
            />
            <Field
              style={{ textIndent: '10px' }}
              placeholder={t('Enter Email')}
              className="border rounded  w-full mb-4 p-1"
              name="email"
              type="text"
            />
            <Field
              style={{ textIndent: '10px' }}
              placeholder={t('Enter Phone')}
              className="border rounded  w-full mb-4 p-1"
              name="phone"
              type="number"
            />
            <Field
              style={{ textIndent: '10px' }}
              placeholder={t('Enter your new password')}
              className="border rounded  w-full mb-4 p-1"
              name="password"
              type="password"
              autoComplete="on"
            />
            <Field
              style={{ textIndent: '10px' }}
              placeholder="Enter your new password"
              className="border rounded  w-full mb-4 p-1 "
              name="role"
              as="select"
            >
              {roles &&
                roles.data &&
                roles.data.data &&
                roles.data.data.map((role) => {
                  return (
                    <option key={role.id} value={`${role.id}`}>
                      {role.name}
                    </option>
                  );
                })}
            </Field>
            <Field
              style={{ textIndent: '10px' }}
              placeholder="Enter your new password"
              className="border rounded  w-full mb-4 p-1 hidden"
              name="isActive"
              type="checkbox"
            />
          </Modal>
        </Form>
      </Formik>
    </>
  );
}
