// eslint-disable-next-line import/no-anonymous-default-export
export default {

  Users: 'المستخدمين',
  'Add User': 'إضافة مستخدم',
  'Edit User': 'تعديل مستخدم',
  'Enter Email': 'برجاء إدخال البريد الإلكتروني',
  'Enter Phone': 'برجاء إدخال رقم التليفون',
  'Enter your new password': 'برجاء إدخال كلمة السر',
  name: 'اسم',
  email: 'البريد الإلكتروني',
  phone: 'هاتف',
  created: 'تاريخ الإنشاء',
  lastlogin: 'آخر تسجيل دخول',
  state: 'حالة',
  Role: 'الصلاحيات',
  options: 'الخيارات',
  'User Status, Updated': 'تم تحديث حالة المستخدم بنجاح',
  "Add Permission": "إضافة إذن ",
  "Edit Permission To": "تحرير تصريح لـ ",
  "Add New Permission": "اضاقة اذن جديد",
  "Select Parent": " اختر ربط بالصفحه",
  "Select Category": 'اختر الفئة',
  "Please Select Parent": " اختر ربط بالصفحه",
  "Please Select Category": 'اختر الفئة',

};
