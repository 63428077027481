import axios from 'axios';

export const Authenticate = async (e) => {
  const { data } = await axios.post('AccountMangment/authenticate', e);
  return data;
};

export const getCurrentUser = async () => {
  const query = await axios.get('UserAccount/CurrentUser');
  const data = query.data.data;
  const isDone = query.data.isDone;
  return { data, isDone };
};

export const getCurrentUserRole = async () => {
  const data = await axios.get('UserAccount/CurrentUser').then((res) =>{
    return res.data.data
  });
  return { data };
};
