import { useTranslation } from 'react-i18next';
import Chart from "react-apexcharts";



export default function PollAnswerOption(props) {
  const { pollQOptionHelper, languageId, pollQOptionLng, mainIndex, subIndex, index, pollQOptionLngs } = props;
  const { i18n, t } = useTranslation();
  const chartCountState = {
    series: [
      {
        name: "COUNT",
        data: []
      },
    ],
      options: {
        chart: {
          id: ""
        },
        xaxis: {
          categories: []
        },
        plotOptions: {
          bar: {
            columnWidth: '30%'
          }
        },
      },
  }
  const chartPercentageState = {
    options: {
      labels: []
    },
    series: [],
  }

  pollQOptionLng.pollQOptions.map(value =>
    {
      chartCountState.options.xaxis.categories.push(value.pollQOptionLngs[languageId -1].title)
      chartPercentageState.options.labels.push(value.pollQOptionLngs[languageId -1].title)
      chartPercentageState.series.push(value.pollQOptionAnswersPercentage)
      chartCountState.series[0].data.push(value.pollQOptionAnswersCount)
    }
  )
  return (
    <>
      {languageId === pollQOptionLng.pollQuestionLngs[index].languageId && (
        <>
          <table className="w-full mt-4 text-sm text-left text-gray-500 dark:text-gray-400  p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 mt-4">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"><tr>
              <th scope="col" className="px-6 py-3 w-3/4" >Answers</th>
              <th scope="col" className="px-6 py-3" >Percentage</th>
              <th scope="col" className="px-6 py-3" >Count</th>
            </tr>
            </thead>

            {pollQOptionLng.pollQOptions.map((val , index) => {
              return (
                <tr key={index} className='bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 '>
                  <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                    {val.pollQOptionLngs[languageId-1].title}</th>
                  <td className="px-6 py-4 text-center">{val.pollQOptionAnswersPercentage}</td>
                  <td className="px-6 py-4 text-center">{val.pollQOptionAnswersCount}</td>
                </tr>
              )
            })}
          </table>
          <div className="flex flex-row h-72 gap-2  mt-4">
          <div className=" bg-white border flex-grow border-gray-200 rounded-lg shadow pl-4 ">
            <Chart
              options={chartCountState.options}
              series={chartCountState.series}
              type="bar"
              width={'100%'}
              height={'100%'}
            />
          </div>
            <div className=" bg-white border flex-grow border-gray-200 rounded-lg shadow  pt-8 ">
          <Chart
            options={chartPercentageState.options}
            series={chartPercentageState.series}
            type="donut"
          />
          </div>
          </div>
        </>
      )}
    </>
  );
}

