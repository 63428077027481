import formik, { ErrorMessage, Field } from "formik";
import MinusCircle from "../../../SharedComponents/Svg/MinusCircle";
import { useTranslation } from "react-i18next";


export const CustomClaimForm = (props) => {
  const { pageParentId, pageCategoryId, index, arrayHelpers, setFieldValue } = props
  const { t, i18n } = useTranslation();
  return (<div key={index} className="flex-row flex ax-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow my-2 gap-4 ">

    <div className="flex flex-col flex-grow gap-4 ">
      <div>
        <Field
          style={{ textIndent: '10px' }}
          placeholder={t('Select Parent Page')}
          className="border rounded  w-full  p-1 block "
          onChange={(e) => {
            setFieldValue(
              `permission[${index}].value.pageParentId`,
              parseInt(e.target.value)
            );
          }}
          name={`permission[${index}].value.pageParentId`}

          as="select"
        >
          <option selected value={0} label={` --- ${t('Select Parent Page')} ---`}>

          </option>

          {pageParentId &&
            pageParentId.data &&
            pageParentId.data.data &&
            pageParentId.data.data.map((parent, index) => {
              return (
                <option key={index} value={parent.id}>
                  {parent.pgPageLngs[0].title}
                </option>
              );
            })}
        </Field>
        <ErrorMessage
          name={`permission[${index}].value.pageParentId`}
          component="span"
          className="text-red-700  pl-2 text-xs"
        />
      </div>
      <div>
        <Field
          style={{ textIndent: '10px' }}
          onChange={(e) => {
            setFieldValue(
              `permission[${index}].value.pageCategoryId`,
              parseInt(e.target.value)
            );
          }}
          placeholder={t('Select Category')}
          className="border rounded  w-full p-1 block "
          name={`permission[${index}].value.pageCategoryId`}
          as="select"
        >
          <option selected value={0}>
            --- {t('Select Category')} ---
          </option>
          {pageCategoryId &&
            pageCategoryId.data &&
            pageCategoryId.data.data &&
            pageCategoryId.data.data.map((category) => {
              return (
                <option key={category.id} value={category.id}>
                  {category.pgPageCategoryLngs[0].name}
                </option>
              );
            })}
        </Field>
        <ErrorMessage
          name={`permission[${index}].value.pageCategoryId`}
          component="span"
          className="text-red-700  pl-2 text-xs"
        />
      </div>
    </div>
    <div className="flex justify-center items-center  ">
      <button
        type="button"
        onClick={() => arrayHelpers.remove(index)}
      >
        <MinusCircle className="h-6 w-6  text-red-700 hover:text-red-800 "/>
      </button>

    </div>
  </div>)
}
