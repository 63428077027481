import { useEffect, useState } from 'react';
import Cog from '../../../SharedComponents/Svg/Cog';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { FieldArray, Form, Formik, useFormikContext } from 'formik';
import Modal from '../../../SharedComponents/Modal';
import { getCustomClaims, getPageCategoryId, getPageParentId } from './ApiUser';
import PlusCircle from '../../../SharedComponents/Svg/PlusCircle';
import { CustomClaimForm } from './CustomClaimForm';
import * as Yup from 'yup';
import axios from 'axios';

const ModalContainer = (props) => {
  const { id, name, isOpen, setIsOpen, data, isLoading } = props;

  const pageCategoryId = useQuery('categoryId', getPageCategoryId);
  const pageParentId = useQuery('parentId', getPageParentId);
  const { t, i18n } = useTranslation();
  const initialValues = {
    permission: data,
  };
  const validationSchema = Yup.object({
    permission: Yup.array().of(
      Yup.object().shape({
        value: Yup.object().shape({
          pageCategoryId: Yup.number(`${t('Please Select Category')}`)
            .required(`${t('Please Select Category')}`)
            .positive(`${t('Please Select Category')}`),
          pageParentId: Yup.number(`${t('Please Select Parent')}`),
        }),
      })
    ),
  });

  const onSubmit = (e) => {
    const data = {
      userId: id,
      claims: [],
    };
    e.permission?.map((ele) => {
      data.claims.push({
        type: 1,
        value: {
          pageCategoryId: ele.value.pageCategoryId,
          pageParentId: ele.value.pageParentId,
        },
      });
    });

    axios
      .post('/UserAccount/AssignUserClaims', JSON.stringify(data), { headers: { 'Content-Type': 'application/json' } })
      .then(
        (response) => {
          setIsOpen(!isOpen);
          console.log('data', data);
        },
        (error) => {
          console.log(error);
        }
      );
  };
  return (
    <>
      <Formik onSubmit={onSubmit} validationSchema={validationSchema} initialValues={initialValues}>
        {({ values, setFieldValue }) => {
          return (
            <Form>
              <Modal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                title={`${t('Edit Permission To')} ${name}`}
                add={true}
                onSubmit={onSubmit}
              >
                <FieldArray
                  name="permission"
                  render={(arrayHelpers) => (
                    <div className="flex flex-col ">
                      {isLoading ? (
                        <div>Loading..</div>
                      ) : (
                        <>
                          {values.permission &&
                            values.permission.length > 0 &&
                            values.permission.map((value, index) => {
                              return (
                                <CustomClaimForm
                                  pageCategoryId={pageCategoryId}
                                  pageParentId={pageParentId}
                                  index={index}
                                  arrayHelpers={arrayHelpers}
                                  value={value}
                                  permission={values.permission.value}
                                  setFieldValue={setFieldValue}
                                />
                              );
                            })}
                          <div
                            onClick={() => {
                              arrayHelpers.push({
                                type: 1,
                                value: {
                                  pageCategoryId: 0,
                                  pageParentId: 0,
                                },
                              });
                            }}
                            className={`bg-lightgreen ${
                              i18n.language === 'en' ? '' : 'space-x-reverse'
                            } space-x-1 text-sm  font-bold text-white flex items-center hover:bg-lightgreen shadow  px-4 py-2 rounded transition duration-300 ease-in-out `}
                          >
                            <PlusCircle className="w-5 h-5"/> <span>{t('Add Permission')}</span>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                />
              </Modal>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

const CustomClaim = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { id, name } = props;
  const [claimData, setClaimData] = useState();
  const { data, isLoading } = useQuery(['customClaims', id], () => getCustomClaims(id));

  return (
    <>
      <button
        onClick={() => {
          setIsOpen(!isOpen);
          setClaimData(data);
        }}
        className="bg-yellow-100 text-yellow-800 hover:bg-yellow-200  p-1 rounded transition duration-300 ease-in-out"
      >
        <Cog className="w-5 h-5"/>
      </button>
      {isOpen && (
        <ModalContainer
          id={id}
          name={name}
          setClaimData={setClaimData}
          isOpen={isOpen}
          data={claimData}
          isLoading={isLoading}
          setIsOpen={setIsOpen}
        />
      )}
    </>
  );
};
export default CustomClaim;
