import axios from 'axios';

export const getPolls = async (e) => {
  const query = await axios.post('/Poll/GetAll', e.queryKey[1]);
  const data = query.data.data;
  const isDone = query.data.isDone;
  const paginationResult = query.data.paginationResult;
  return { data, isDone, paginationResult };
};

export const getLanguages = async () => {
  const query = await axios.post('/Language/GetAll');
  const data = query.data.data;
  const isDone = query.data.isDone;
  return { data, isDone };
};

export const toggleStatusPoll = async (e) => {
  const mutation = await axios.post('/Poll/ChangeStatus', e);
  const data = mutation.data.data;
  const isDone = mutation.data.isDone;
  return { data, isDone };
};

export const getPoll = (id, languageId) => {
  return async () => {
    const query = await axios.get(`/Poll/Get?id=${id}&langId=${languageId}`);
    const data = query.data.data;
    const isDone = query.data.isDone;
    return { data, isDone };
  };
};
export const getAnswer = (id, languageId) => {
  return async () => {
    const query = await axios.get(`/Poll/GetWithAnswers?Id=${id}&langId=${languageId}`);
    const data = query.data.data;
    const isDone = query.data.isDone;
    return { data, isDone };
  };
};


export const addPoll = async (e) => {
  const { data } = await axios.post('/Poll/Add', e);
  return data;
};

export const deletePoll = async (e) => {
  const { data } = await axios.post('/Poll/Delete', e);
  return data;
};

export const addPollQuestion = async (e) => {
  const { data } = await axios.post('/PollQuestion/Add', e);
  return data;
};
export const editPollQuestion = async (e) => {
  const { data } = await axios.post('/PollQuestion/Edit', e);
  return data;
};
export const deletePollQuestion = async (e) => {
  const { data } = await axios.post('/PollQuestion/Delete', e);
  return data;
};
export const deletePollQOption = async (e) => {
  const { data } = await axios.post('/PollQuestion/DeleteQOption', e);
  return data;
};
