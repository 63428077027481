import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import PlusCircle from '../../../SharedComponents/Svg/PlusCircle';
import DeleteQOption from './DeleteQOption';

export default function PollQOption(props) {
  const {
    pollQOptionHelper, languageId,
    pollQOptionLng, mainIndex, subIndex, index,
    pollQOptionLngs
  } = props;
  console.log(pollQOptionHelper)
  console.log(pollQOptionLngs)
  const { i18n, t } = useTranslation();
  return (
    <>
      {languageId === pollQOptionLng.languageId && (
        <>
          <div className={`flex items-center space-x-2 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}>
            <Field
              className="border rounded  w-full mb-2 p-1"
              type="text"
              // name='title'
              name={`pollQuestions[${mainIndex}].pollQOptions[${subIndex}].pollQOptionLngs[${index}].title`}
            />
            <div className="flex space-x-2">
              <button
                type="button"
                className="mb-2  transition duration-300 ease-in-out cursor-pointer"
                onClick={() => {
                  pollQOptionHelper.push({
                    pollQOptionLngs: pollQOptionLngs,
                  });
                }}
              >
                <PlusCircle className="h-6 w-6 text-green-600"/>
              </button>
              <DeleteQOption pollQOptionHelper={pollQOptionHelper} index={index}/>
            </div>
          </div>
        </>
      )}
    </>
  );
}
