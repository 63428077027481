import React from 'react';
import { Redirect } from 'react-router-dom';

export const AdminPageGuard = ({ types, children }) => {
  const token = localStorage.getItem('token')
  const role = JSON.parse(token).outObject.roleName

  if (!role) {
    return <Redirect to="/login" />
  }
  if (
    role ==='Admin'
  ) {
    return <>{children}</>;
  }
  return <Redirect to="/" />

};
