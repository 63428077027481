import { useEffect } from 'react';
import './App.css';
import Login from './Login';
import Layout from './Layout';
import useToken from './useToken';
import { BrowserRouter } from 'react-router-dom';
import './Api/AxioxConfig'; // this line for axios interceptors
import { useQuery } from 'react-query';
import { getCurrentUser } from './Api/Authenticate';
import Loading from './SharedComponents/Loading';
import { useTranslation } from 'react-i18next';
import toast, { ToastBar, Toaster } from 'react-hot-toast';
import AnswerPolls from "./Views/Polls/AnswerPolls";

function App() {
  const { setToken } = useToken();
  const { data, isLoading, isError, error } = useQuery(['currentUser'], getCurrentUser, {
    retry: false,
    refetchOnMount: true,
  });

  const { i18n } = useTranslation();
  //
  useEffect(() => {
    document.dir = i18n.dir();
  }, [i18n, i18n.language]);

  if (isLoading) return <Loading />;
  if (isError) return <Login setToken={setToken} />;
  if (!localStorage.getItem('token')) return <Login setToken={setToken} />;

  return (
    <>
      {data && (
        <BrowserRouter>
          <Toaster  />
          <Layout />

        </BrowserRouter>
      )}
    </>
  );
}

export default App;
