import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import ChevronRight from '../../../SharedComponents/Svg/ChevronRight';
import PlusCircle from '../../../SharedComponents/Svg/PlusCircle';
import PollQOption from './PollQOption';
import { addPollQuestion, editPollQuestion, getLanguages } from '../ApiPolls';
import { useParams } from 'react-router';
import DeleteQuestion from './DeleteQuestion';
import Notification from '../../../SharedComponents/Notification';
import { useTranslation } from 'react-i18next';
import PollAnswerOption from "./PollAnswerOption";
import question from "../../../localization/question";

export default function Answer(props) {
  const { pollQOptionLngs, Question, pollQuestions, languageId, mainIndex } = props;
  const [isOpen, setIsOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const params = useParams();
  const queryClient = useQueryClient();
  // Mutate Data to Create New Question
  const addQuestion = useMutation(addPollQuestion, {
    onSuccess: async (data) => {
      queryClient.invalidateQueries(['poll']);

      if (data.isDone) {
        queryClient.invalidateQueries(['poll']);
      }
    },
  });
  // Mutate Data to Create New Question
  const editQuestion = useMutation(editPollQuestion, {
    onSuccess: async (data) => {
      queryClient.invalidateQueries(['poll']);

      if (data.isDone) {
        queryClient.invalidateQueries(['poll']);
      }
    },
  });
  const onSubmit = async (e) => {
    delete e.pollQAnswers;
    if (e.id) {
      try {
        await editQuestion.mutate(e);
      } catch (error) {
        console.log('error', error);
      }
    } else {
      try {
        const id = params.id;
        await addQuestion.mutate({ ...e, pollId: id });
      } catch (error) {
        console.log('error', error);
      }
    }
  };

  return (
    <>
      <div className="border mb-4 shadow bg-white ">
        <div className="  px-4 p-3  flex justify-between">
          <div className={`flex items-center space-x-2 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}>
            <button
              type="button"
              className="focus:outline-none "
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              <ChevronRight
                className={`w-4 h-4 transform ${isOpen ? 'rotate-90' : 'rotate-0'}  transition-all duration-500`}
              />
            </button>
            <div>{Question.pollQuestionLngs[languageId -1 ].header }</div>
          </div>
        </div>
        <div className={`bg-gray-100 px-4   overflow-hidden      ${isOpen ? 'py-3 h-full  ' : '  py-0 h-0'}`}>
          <>
            <p className="text-md font-medium ">Question</p>
            <div className="text-md font-medium pl-4 mt-4"  >{Question.pollQuestionLngs[languageId -1 ].header}</div>
          </>
          <div>
            {pollQOptionLngs.map(
              (pollQOptionLng, index) =>
                <PollAnswerOption
                  pollQOptionLngs={pollQOptionLngs}
                  mainIndex={mainIndex}
                  index={index}
                  languageId={languageId}
                  pollQOptionLng={Question}
                />)}
          </div>
          {editQuestion.isSuccess && <Notification message={t('Poll Question Updated')} />}
          {addQuestion.isSuccess && <Notification message={t('Poll Question Added')} />}
        </div>
      </div>
    </>
  );
}
