import { useQuery, useQueryClient } from 'react-query';
import Modal from '../../../SharedComponents/Modal';
import { getRoles } from './ApiRoles';
import EditRole from './EditRole';
import AddRole from './AddRole';
import DeleteRole from './DeleteRole';
import Home from '../../../SharedComponents/Svg/Home';
import { useState } from 'react';
import RolePermission from './RolePermission';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BreadCrumbs from '../../../SharedComponents/BreadCrumbs';

export default function Roles() {
  const { data } = useQuery(['roles'], getRoles, {
    staleTime: 9999999999,
  });
  const { t, i18n } = useTranslation();
  // to control how many rows to show in table

  const [entries, setEntries] = useState(10);
  const onChangeEntries = (e) => {
    setEntries(e.target.value);
  };
  return (
    <>
      <header className="flex justify-between">
        <BreadCrumbs to="/roles" name={t('Roles')} />

        {/* <div className="flex items-center bg-white rounded border shadow">
          <div className="pl-4 pr-12 py-4 relative overflow-hidden">
            <a href="/">
              <Home />
            </a>
            <div className="text-gray-100">
              <hr className="w-11 border text-gray-50 border-gray-50 absolute transform -rotate-45 right-0 bottom-0 mb-3" />
              <hr className="w-11 border text-gray-50 border-gray-50 absolute transform rotate-45 right-0 top-0 mt-3" />
            </div>
          </div>
          <div className="pl-4 pr-12 py-4 relative overflow-hidden">
            <a href="/dashboard">Dashboard</a>
            <div className="text-gray-100">
              <hr className="w-11 border text-gray-50 border-gray-50 absolute transform -rotate-45 right-0 bottom-0 mb-3" />
              <hr className="w-11 border text-gray-50 border-gray-50 absolute transform rotate-45 right-0 top-0 mt-3" />
            </div>
          </div>

          <div className="pl-4 pr-12 py-4">
            <Link to="roles">Roles</Link>
          </div>
        </div> */}
        <div>
          <AddRole />
        </div>
      </header>
      <div className="my-6">
        <h2 className="text-2xl font-bold">{t('User Role')} </h2>{' '}
      </div>
      {/* Table */}
      <div className="bg-white rounded border shadow-md">
        <div className="p-6">
          <div
            className={`flex items-center ${i18n.language === 'en' ? '' : 'space-x-reverse'} space-x-2 text-gray-600`}
          >
            <span>{t('Show')} </span>
            <select className="border rounded w-20 py-2 px-1" onChange={onChangeEntries}>
              <option value="10">10 </option>
              <option value="20">20 </option>
              <option value="30">30 </option>
            </select>{' '}
            <span>{t('entries')}</span>
          </div>
        </div>

        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className=" overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y-2 divide-gray-200">
                  <thead className=" text-sm font-bold text-black">
                    <tr>
                      <th
                        scope="col"
                        className={`px-6 py-3 ${i18n.language === 'en' ? 'text-left' : 'text-right'} uppercase `}
                      >
                        {t('Name')}
                      </th>
                      <th
                        scope="col"
                        className={`px-6 py-3 ${i18n.language === 'en' ? 'text-left' : 'text-right'} uppercase w-64 `}
                      >
                        {t('Options')}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200 relative text-base text-darkgray font-bold">
                    {data &&
                      data?.data?.map((role) => {
                        console.log(role)
                        return (
                          <tr key={role.id} className="">
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className=" ">{role.name}</div>
                            </td>
                            <td
                              className={`px-6 py-4 whitespace-nowrap flex items-center ${
                                i18n.language === 'en' ? '' : 'space-x-reverse'
                              } space-x-2`}
                            >
                              <EditRole id={role.id} name={role.name} />
                              <RolePermission name={role.name} />
                              <DeleteRole id={role.id} />
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
